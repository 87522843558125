import { ArrowDownward } from '@material-ui/icons';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

const MaterialsHeader = (): JSX.Element => {

  const data = useStaticQuery(graphql`
  query {
    puzzle: contentfulAsset(title: {eq: "Group 285"}) {
      fluid(maxWidth: 1031) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
  }
  `);

  return (
    <div className="materials-header">
      <div className="materials-header__title">
        <h1>ZOBACZ, CO DLA CIEBIE PRZYGOTOWALIŚMY!</h1>
        <Link to="/materiały/#materials"><ArrowDownward fontSize="inherit" /></Link>
      </div>
      <div className="materials-header__img">
        <Img fluid={data.puzzle.fluid} />
      </div>
    </div>
  );
};

export default MaterialsHeader;