import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useStaticQuery, graphql } from 'gatsby';
import React, { useState } from 'react';

import ListHeader from 'components/DiscussionList/List/ListHeader';
import { ContentfulMaterialNode } from 'models/contentfulModels';
import { StringForm } from 'models/typeAliases';

const MaterialsTiles = (): JSX.Element => {

  const data = useStaticQuery(graphql`
    query {
      materials: allContentfulMaterials(sort: {fields: updatedAt, order: DESC}) {
        edges {
          node {
            title
            slug
            theme
            createdAt
            category
            externalLink
            description {
              json
            }
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  const sortingList = ['Od najnowszego materiału', 'Od najstarszego materiału'];
  const themeList = [
    ...new Set(data.materials.edges.map((item: ContentfulMaterialNode) => item.node.theme))
  ] as string[];

  const [form, setForm] = useState<StringForm>({ topic: 'all', order: sortingList[0] });

  const defaultList = data.materials.edges as ContentfulMaterialNode[];
  let displayedList: ContentfulMaterialNode[] | undefined;
  if (form.order === sortingList[0]) {
    displayedList = defaultList.sort((a, b) => 
      new Date(b.node.createdAt).getTime() - new Date(a.node.createdAt).getTime()
    );
  }
  else if (form.order === sortingList[1]) {
    displayedList = defaultList.sort((a, b) => 
      new Date(a.node.createdAt).getTime() - new Date(b.node.createdAt).getTime()
    );
  }
  if (form.topic) {
    if (form.topic === 'all') displayedList = defaultList;
    else displayedList = defaultList.filter(item => item.node.theme === form.topic);
  }

  return (
    <div className="materials-tiles" id="materials">
      <ListHeader
        title={'Materiały'}
        form={form}
        setForm={setForm}
        themeList={themeList}
        sortingList={sortingList}
      />
      <div className="materials-tiles__list">
        {displayedList?.map(item => (
          <a
            key={item.node.slug}
            href={item.node.category === 'Infografika' ? `/materiały/${item.node.slug}` : item.node.externalLink}
            rel="noopener noreferrer" 
            target="_blank"
            className="materials__tile"
          >
            <img className="materials__img" src={`https:${item.node.image.file.url}`} alt={item.node.title} />
            <h1>
              {item.node.category}
            </h1>
            <h2>
              {item.node.title}
            </h2>
            <div className="materials__desc">
              {documentToReactComponents(item.node.description.json)}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default MaterialsTiles;