import React from 'react';

import Footer from 'components/Footer';
import Layout from 'components/Layout';
import MaterialsBody from 'components/Materials/MaterialsBody';
import MaterialsHeader from 'components/Materials/MaterialsHeader';
import MaterialsTiles from 'components/Materials/MaterialsTiles';
import SEO from 'components/SEO';

const Materiały = (): JSX.Element => {
  return (
    <Layout>
      <SEO title="Materiały" />
      <MaterialsHeader />
      <MaterialsBody>
        <MaterialsTiles />
      </MaterialsBody>
      <Footer />
    </Layout>
  );
};

export default Materiały;