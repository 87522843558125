import { TextField, MenuItem } from '@material-ui/core';
import React from 'react';

import { handleFormChange } from 'components/Headers/DiscussionProposal/formHelper';
import { StringForm, SetStringForm } from 'models/typeAliases';

type Props = {
  title: string;
  form: StringForm;
  setForm: SetStringForm;
  themeList: string[];
  sortingList: string[];
}

const ListHeader = (props: Props): JSX.Element => {

  return (
    <div className="discussion-list__header">
      <h1>{props.title}</h1>
      <div className="discussion-list__sort">
        <TextField
          label="Dyskusje w temacie"
          select
          value={props.form.topic}
          onChange={handleFormChange(props.form, props.setForm, 'topic')}
          SelectProps={{
            displayEmpty: true,
            MenuProps: { disableScrollLock: true }
          }}
          margin="normal"
        >
          <MenuItem value="all">Wszystkie tematy</MenuItem>
          {props.themeList.map(topic => (
            <MenuItem key={topic} value={topic}>{topic}</MenuItem>
          ))}
        </TextField>
        <TextField
          label="W kolejności"
          select
          value={props.form.order}
          onChange={handleFormChange(props.form, props.setForm, 'order')}
          SelectProps={{
            displayEmpty: true,
            MenuProps: { disableScrollLock: true }
          }}
          margin="normal"
        >
          {props.sortingList.map(sort => 
            <MenuItem key={sort} value={sort}>{sort}</MenuItem>
          )}
        </TextField>
      </div>
    </div>
  );
};

export default ListHeader;