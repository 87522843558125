import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

type Props = {
  children: JSX.Element;
}

const MaterialsBody = (props: Props): JSX.Element => {

  const data = useStaticQuery(graphql`
    query {
      waveTop: contentfulAsset(title: {eq: "Path 673"}) {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      waveBottom: contentfulAsset(title: {eq: "Path 210"}) {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
  `);

  return (
    <div className="materials-body">
      <Img className="wave wave--top" fluid={data.waveTop.fluid} />
      <main>{props.children}</main>
      <Img className="wave wave--bottom" fluid={data.waveBottom.fluid} />
    </div>
  );
};

export default MaterialsBody;